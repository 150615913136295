export const appPageTransition = {"name":"page","mode":"out-in"}

export const appLayoutTransition = {"name":"layout","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""}],"link":[{"rel":"manifest","href":"/manifest.json"}],"style":[],"script":[],"noscript":[],"title":"Visolva Smartdusche"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null