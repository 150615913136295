import { default as indexIMrRcMJZ6fMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/auth/index.vue?macro=true";
import { default as indexRopLPHHbucMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as indexZrzVI5SPEzMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/index/index.vue?macro=true";
import { default as orderiJfEA3D7vdMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/index/order.vue?macro=true";
import { default as orderSuccessXTyOgAmRK5Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/index/orderSuccess.vue?macro=true";
import { default as indexWcG8KewzXoMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/index.vue?macro=true";
import { default as attributeBaseDataXzKnSCE1VAMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/attributes/[id]/attributeBaseData.vue?macro=true";
import { default as _91id_93sibmoiKjmjMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/attributes/[id].vue?macro=true";
import { default as componentBaseDatanP2FxMY0ESMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/components/[id]/componentBaseData.vue?macro=true";
import { default as _91id_93BivujpgOwlMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/components/[id].vue?macro=true";
import { default as index01rcDqtPexMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/components/index.vue?macro=true";
import { default as variantBaseDataPPRg9bce0KMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/variants/[id]/variantBaseData.vue?macro=true";
import { default as _91id_93KzOLkYMGbcMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/variants/[id].vue?macro=true";
import { default as indexyhduLsTVFWMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/variants/index.vue?macro=true";
import { default as configuratordKcStuR3pCMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator.vue?macro=true";
import { default as indexRWbe2QtWmVMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/index.vue?macro=true";
import { default as orderBaseData7jggDsutgfMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/orders/[id]/orderBaseData.vue?macro=true";
import { default as _91id_93lDnX7YPaXMMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/orders/[id].vue?macro=true";
import { default as indexW7s5MOIa88Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/orders/index.vue?macro=true";
import { default as languagejNa9pG6CvaMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/settings/language.vue?macro=true";
import { default as _91id_93Uq1hOJtVE7Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/settings/loginProviders/[id].vue?macro=true";
import { default as indexClL2yyBpa7Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/settings/loginProviders/index.vue?macro=true";
import { default as rolesLxRakNhDHZMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/settings/roles.vue?macro=true";
import { default as settingsTDjxiCBkvxMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/settings.vue?macro=true";
import { default as userActivitieski71mnCPvWMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDatartVgByCHYTMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsRhVDs26CYnMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93icU03AoAweMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/users/[id].vue?macro=true";
import { default as indexIrHAXK75rSMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/users/index.vue?macro=true";
import { default as usersIHKXLWnMO8Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/users.vue?macro=true";
import { default as service8Cmw02ndVKMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service.vue?macro=true";
export default [
  {
    name: indexIMrRcMJZ6fMeta?.name ?? "auth",
    path: indexIMrRcMJZ6fMeta?.path ?? "/auth",
    meta: indexIMrRcMJZ6fMeta || {},
    alias: indexIMrRcMJZ6fMeta?.alias || [],
    redirect: indexIMrRcMJZ6fMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexRopLPHHbucMeta?.name ?? "auth-social-organisation-provider",
    path: indexRopLPHHbucMeta?.path ?? "/auth/social/:organisation()/:provider()",
    meta: indexRopLPHHbucMeta || {},
    alias: indexRopLPHHbucMeta?.alias || [],
    redirect: indexRopLPHHbucMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    path: indexWcG8KewzXoMeta?.path ?? "/",
    children: [
  {
    name: indexZrzVI5SPEzMeta?.name ?? "index",
    path: indexZrzVI5SPEzMeta?.path ?? "",
    meta: indexZrzVI5SPEzMeta || {},
    alias: indexZrzVI5SPEzMeta?.alias || [],
    redirect: indexZrzVI5SPEzMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: orderiJfEA3D7vdMeta?.name ?? "index-order",
    path: orderiJfEA3D7vdMeta?.path ?? "order",
    meta: orderiJfEA3D7vdMeta || {},
    alias: orderiJfEA3D7vdMeta?.alias || [],
    redirect: orderiJfEA3D7vdMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/index/order.vue").then(m => m.default || m)
  },
  {
    name: orderSuccessXTyOgAmRK5Meta?.name ?? "index-orderSuccess",
    path: orderSuccessXTyOgAmRK5Meta?.path ?? "orderSuccess",
    meta: orderSuccessXTyOgAmRK5Meta || {},
    alias: orderSuccessXTyOgAmRK5Meta?.alias || [],
    redirect: orderSuccessXTyOgAmRK5Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/index/orderSuccess.vue").then(m => m.default || m)
  }
],
    name: indexWcG8KewzXoMeta?.name ?? undefined,
    meta: indexWcG8KewzXoMeta || {},
    alias: indexWcG8KewzXoMeta?.alias || [],
    redirect: indexWcG8KewzXoMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/index.vue").then(m => m.default || m)
  },
  {
    path: service8Cmw02ndVKMeta?.path ?? "/service",
    children: [
  {
    name: configuratordKcStuR3pCMeta?.name ?? "service-configurator",
    path: configuratordKcStuR3pCMeta?.path ?? "configurator",
    children: [
  {
    name: _91id_93sibmoiKjmjMeta?.name ?? "service-configurator-attributes-id",
    path: _91id_93sibmoiKjmjMeta?.path ?? "attributes/:id()",
    children: [
  {
    name: attributeBaseDataXzKnSCE1VAMeta?.name ?? "service-configurator-attributes-id-attributeBaseData",
    path: attributeBaseDataXzKnSCE1VAMeta?.path ?? "attributeBaseData",
    meta: attributeBaseDataXzKnSCE1VAMeta || {},
    alias: attributeBaseDataXzKnSCE1VAMeta?.alias || [],
    redirect: attributeBaseDataXzKnSCE1VAMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/attributes/[id]/attributeBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93sibmoiKjmjMeta || {},
    alias: _91id_93sibmoiKjmjMeta?.alias || [],
    redirect: _91id_93sibmoiKjmjMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/attributes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93BivujpgOwlMeta?.name ?? "service-configurator-components-id",
    path: _91id_93BivujpgOwlMeta?.path ?? "components/:id()",
    children: [
  {
    name: componentBaseDatanP2FxMY0ESMeta?.name ?? "service-configurator-components-id-componentBaseData",
    path: componentBaseDatanP2FxMY0ESMeta?.path ?? "componentBaseData",
    meta: componentBaseDatanP2FxMY0ESMeta || {},
    alias: componentBaseDatanP2FxMY0ESMeta?.alias || [],
    redirect: componentBaseDatanP2FxMY0ESMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/components/[id]/componentBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93BivujpgOwlMeta || {},
    alias: _91id_93BivujpgOwlMeta?.alias || [],
    redirect: _91id_93BivujpgOwlMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/components/[id].vue").then(m => m.default || m)
  },
  {
    name: index01rcDqtPexMeta?.name ?? "service-configurator-components",
    path: index01rcDqtPexMeta?.path ?? "components",
    meta: index01rcDqtPexMeta || {},
    alias: index01rcDqtPexMeta?.alias || [],
    redirect: index01rcDqtPexMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/components/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KzOLkYMGbcMeta?.name ?? "service-configurator-variants-id",
    path: _91id_93KzOLkYMGbcMeta?.path ?? "variants/:id()",
    children: [
  {
    name: variantBaseDataPPRg9bce0KMeta?.name ?? "service-configurator-variants-id-variantBaseData",
    path: variantBaseDataPPRg9bce0KMeta?.path ?? "variantBaseData",
    meta: variantBaseDataPPRg9bce0KMeta || {},
    alias: variantBaseDataPPRg9bce0KMeta?.alias || [],
    redirect: variantBaseDataPPRg9bce0KMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/variants/[id]/variantBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93KzOLkYMGbcMeta || {},
    alias: _91id_93KzOLkYMGbcMeta?.alias || [],
    redirect: _91id_93KzOLkYMGbcMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/variants/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyhduLsTVFWMeta?.name ?? "service-configurator-variants",
    path: indexyhduLsTVFWMeta?.path ?? "variants",
    meta: indexyhduLsTVFWMeta || {},
    alias: indexyhduLsTVFWMeta?.alias || [],
    redirect: indexyhduLsTVFWMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator/variants/index.vue").then(m => m.default || m)
  }
],
    meta: configuratordKcStuR3pCMeta || {},
    alias: configuratordKcStuR3pCMeta?.alias || [],
    redirect: configuratordKcStuR3pCMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/configurator.vue").then(m => m.default || m)
  },
  {
    name: indexRWbe2QtWmVMeta?.name ?? "service",
    path: indexRWbe2QtWmVMeta?.path ?? "",
    meta: indexRWbe2QtWmVMeta || {},
    alias: indexRWbe2QtWmVMeta?.alias || [],
    redirect: indexRWbe2QtWmVMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lDnX7YPaXMMeta?.name ?? "service-orders-id",
    path: _91id_93lDnX7YPaXMMeta?.path ?? "orders/:id()",
    children: [
  {
    name: orderBaseData7jggDsutgfMeta?.name ?? "service-orders-id-orderBaseData",
    path: orderBaseData7jggDsutgfMeta?.path ?? "orderBaseData",
    meta: orderBaseData7jggDsutgfMeta || {},
    alias: orderBaseData7jggDsutgfMeta?.alias || [],
    redirect: orderBaseData7jggDsutgfMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/orders/[id]/orderBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93lDnX7YPaXMMeta || {},
    alias: _91id_93lDnX7YPaXMMeta?.alias || [],
    redirect: _91id_93lDnX7YPaXMMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexW7s5MOIa88Meta?.name ?? "service-orders",
    path: indexW7s5MOIa88Meta?.path ?? "orders",
    meta: indexW7s5MOIa88Meta || {},
    alias: indexW7s5MOIa88Meta?.alias || [],
    redirect: indexW7s5MOIa88Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/orders/index.vue").then(m => m.default || m)
  },
  {
    name: settingsTDjxiCBkvxMeta?.name ?? "service-settings",
    path: settingsTDjxiCBkvxMeta?.path ?? "settings",
    children: [
  {
    name: languagejNa9pG6CvaMeta?.name ?? "service-settings-language",
    path: languagejNa9pG6CvaMeta?.path ?? "language",
    meta: languagejNa9pG6CvaMeta || {},
    alias: languagejNa9pG6CvaMeta?.alias || [],
    redirect: languagejNa9pG6CvaMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/settings/language.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Uq1hOJtVE7Meta?.name ?? "service-settings-loginProviders-id",
    path: _91id_93Uq1hOJtVE7Meta?.path ?? "loginProviders/:id()",
    meta: _91id_93Uq1hOJtVE7Meta || {},
    alias: _91id_93Uq1hOJtVE7Meta?.alias || [],
    redirect: _91id_93Uq1hOJtVE7Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexClL2yyBpa7Meta?.name ?? "service-settings-loginProviders",
    path: indexClL2yyBpa7Meta?.path ?? "loginProviders",
    meta: indexClL2yyBpa7Meta || {},
    alias: indexClL2yyBpa7Meta?.alias || [],
    redirect: indexClL2yyBpa7Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: rolesLxRakNhDHZMeta?.name ?? "service-settings-roles",
    path: rolesLxRakNhDHZMeta?.path ?? "roles",
    meta: rolesLxRakNhDHZMeta || {},
    alias: rolesLxRakNhDHZMeta?.alias || [],
    redirect: rolesLxRakNhDHZMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/settings/roles.vue").then(m => m.default || m)
  }
],
    meta: settingsTDjxiCBkvxMeta || {},
    alias: settingsTDjxiCBkvxMeta?.alias || [],
    redirect: settingsTDjxiCBkvxMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/settings.vue").then(m => m.default || m)
  },
  {
    path: usersIHKXLWnMO8Meta?.path ?? "users",
    children: [
  {
    name: _91id_93icU03AoAweMeta?.name ?? "service-users-id",
    path: _91id_93icU03AoAweMeta?.path ?? ":id()",
    children: [
  {
    name: userActivitieski71mnCPvWMeta?.name ?? "service-users-id-userActivities",
    path: userActivitieski71mnCPvWMeta?.path ?? "userActivities",
    meta: userActivitieski71mnCPvWMeta || {},
    alias: userActivitieski71mnCPvWMeta?.alias || [],
    redirect: userActivitieski71mnCPvWMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: userBaseDatartVgByCHYTMeta?.name ?? "service-users-id-userBaseData",
    path: userBaseDatartVgByCHYTMeta?.path ?? "userBaseData",
    meta: userBaseDatartVgByCHYTMeta || {},
    alias: userBaseDatartVgByCHYTMeta?.alias || [],
    redirect: userBaseDatartVgByCHYTMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: userCommunicationsRhVDs26CYnMeta?.name ?? "service-users-id-userCommunications",
    path: userCommunicationsRhVDs26CYnMeta?.path ?? "userCommunications",
    meta: userCommunicationsRhVDs26CYnMeta || {},
    alias: userCommunicationsRhVDs26CYnMeta?.alias || [],
    redirect: userCommunicationsRhVDs26CYnMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
],
    meta: _91id_93icU03AoAweMeta || {},
    alias: _91id_93icU03AoAweMeta?.alias || [],
    redirect: _91id_93icU03AoAweMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexIrHAXK75rSMeta?.name ?? "service-users",
    path: indexIrHAXK75rSMeta?.path ?? "",
    meta: indexIrHAXK75rSMeta || {},
    alias: indexIrHAXK75rSMeta?.alias || [],
    redirect: indexIrHAXK75rSMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/users/index.vue").then(m => m.default || m)
  }
],
    name: usersIHKXLWnMO8Meta?.name ?? undefined,
    meta: usersIHKXLWnMO8Meta || {},
    alias: usersIHKXLWnMO8Meta?.alias || [],
    redirect: usersIHKXLWnMO8Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service/users.vue").then(m => m.default || m)
  }
],
    name: service8Cmw02ndVKMeta?.name ?? undefined,
    meta: service8Cmw02ndVKMeta || {},
    alias: service8Cmw02ndVKMeta?.alias || [],
    redirect: service8Cmw02ndVKMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20241211080429/client/pages/service.vue").then(m => m.default || m)
  }
]